<template>
    <div class="applypromoter">
        <div class="title-box" v-if="types == 6">
            <p class="title">{{ detail.title }}</p>
            <p class="time">发布时间：{{ detail.create_time }}</p>
        </div>
        <p v-html="detail.long_contents"></p>
        <div class="applypromoter-wrap" v-if="false">
            <p>
                <img :src="detail.cover_imgurl" alt />
            </p>
            <div class="applypromoter-wrap-bottom">
                <p>
                    {{ detail.title }}
                    <span></span>
                </p>
                <p v-html="detail.long_contents"></p>
            </div>
        </div>
        <div class="applypromoter-footer" v-if="false">

        </div>
    </div>
</template>

<style lang="scss">
    .applypromoter {
        width: 100%;
        font-size: 0.26rem;
        padding: 0.24rem;
        box-sizing: border-box;
        margin-bottom: 20px;

        .title-box {
            border-bottom: #f0f0f0 solid 1px;
            padding-bottom: 0.3rem;
            margin-bottom: 0.3rem;

            .title {
                font-size: 0.3rem;
                color: #333;
                margin-bottom: 0.1rem;
                font-weight: 600;
            }
        }

        img {
            width: 100% !important;
        }

        .my-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.3rem;
            box-sizing: border-box;
            font-size: 0.38rem;
            background: #f4f4f4;
        }

        .applypromoter-wrap {
            width: 100%;

            img {
                width: 100%;
                height: 3.4rem;
            }

            .applypromoter-wrap-bottom {
                width: 100%;
                padding: 0.54rem 0.23rem 0 0.23rem;
                box-sizing: border-box;

                p:nth-child(1) {
                    width: 1.52rem;
                    margin: 0 auto;
                    font-size: 0.36rem;
                    color: #070707;
                    text-align: center;
                    position: relative;

                    span {
                        position: absolute;
                        bottom: -0.07rem;
                        left: 0.39rem;
                        display: block;
                        width: 0.76rem;
                        height: 0.04rem;
                        background: #e50012;
                    }
                }

                p:nth-child(2) {
                    font-size: 0.28rem;
                    color: #474747;
                    margin-top: 0.47rem;
                }
            }
        }

        .applypromoter-footer {
            font-size: 0.3rem;
            width: 6.83rem;
            height: 0.84rem;
            background: #e50012;
            color: #ffff;
            text-align: center;
            line-height: 0.84rem;
            border-radius: 0.35rem;
            margin-top: 4.05rem;
            margin-left: 0.33rem;
        }
    }
</style>
<script>
    import {
        getMoneyRule,
        getRewardRule,
        getTalentRule,
        getUserRule,
        bulletinDetails
    } from "@/http/api.js";

    export default {
        props: {
            types: {
                type: String,
                default () {
                    return this.$route.query.types;
                }
            },
            isChangeTitle: {
                type: Boolean,
                default () {
                    return true;
                }
            }
        },
        data() {
            return {
                detail: {}
            };
        },
        methods: {
            //获取饭佣说明
            async getMoneyRule() {
                const res = await getMoneyRule({});
                this.detail = res.data;
            },
            //获取奖励说明
            async getRewardRule() {
                const res = await getRewardRule({});
                this.detail = res.data;
            },
            //达人协议
            async getTalentRule() {
                const res = await getTalentRule({});
                this.detail = res.data;
            },
            // 用户协议
            async getUserRule() {
                const res = await getUserRule({});
                this.detail = res.data;
            },
            // 获取公告详情
            async getNoticeDetails() {
                const res = await bulletinDetails({
                    data: {
                        bulletin_id: this.$route.query.id
                    }
                });
                this.detail = res.data;
            }
        },
        created() {

        },
        mounted() {
            let types = this.types;
            if (types == "2") {
                this.getMoneyRule();
                if (this.isChangeTitle) {
                    document.title = "饭佣说明";
                }
            } else if (types == "3") {
                this.getRewardRule();
                if (this.isChangeTitle) {
                    document.title = "奖励说明";
                }
            } else if (types == "4") {
                this.getTalentRule();
                if (this.isChangeTitle) {
                    document.title = "会员注册协议";
                }
            } else if (types == "5") {
                this.getUserRule();
                if (this.isChangeTitle) {
                    document.title = "平台用户服务协议";
                }
            } else if (types == "6") {
                this.getNoticeDetails();
                if (this.isChangeTitle) {
                    document.title = "公告详情";
                }
            }
        }
    };
</script>
